import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import DownloadBlock, { DownloadLink } from '../DownloadBlock/DownloadBlock';
import CommonData from '../../data/common/common';
import { LocaleTag } from '../../../config/locales';
import './style.scss';

interface OwnProps {
  className?: string;
}

type Props = OwnProps;

const MortgageServicingDocuments: React.FC<Props> = ({ className = '' }) => {
  const { t } = useTranslation(LocaleNameSpaceKeys.MORTGAGE_SERVICING_DOCUMENTS);

  const downloadBase = `/downloads/mortgage-servicing-documents/`;

  const getDownloadBlock = (englishLink: string, frenchLink?: string) => {
    const downloadBlock = [
      {
        to: `${downloadBase}${englishLink}`,
        label: t(`${LocaleNameSpaceKeys.COMMON}:english`),
      },
    ];

    if (frenchLink) {
      downloadBlock.push({
        to: `${downloadBase}${frenchLink}`,
        label: t(`${LocaleNameSpaceKeys.COMMON}:french`),
      });
    }

    return downloadBlock;
  };

  const downloadLinks: {
    heading: string | ReactNode;
    downloadLinks: DownloadLink[];
    body?: ReactNode;
  }[] = [
    {
      heading: <h2 className="h4">{t('payouts.heading')}</h2>,
      body: <p>{t('payouts.body')}</p>,
      downloadLinks: [
        {
          to: CommonData.websites.payout[LocaleTag.EN],
          label: t(`${LocaleNameSpaceKeys.COMMON}:english`),
        },
        {
          to: CommonData.websites.payout[LocaleTag.FR],
          label: t(`${LocaleNameSpaceKeys.COMMON}:french`),
        },
      ],
    },
    {
      heading: <h2 className="h4">{t('authorizationtoDiscloseInformation')}</h2>,
      downloadLinks: getDownloadBlock(
        'AuthorizationToDisclose_ENG(03Aug2021).pdf',
        'AutorisationDivulguerRenseignements_FR(03Aug2021).pdf'
      ),
    },
    {
      heading: <h2 className="h4">{t('feeSchedule')}</h2>,
      downloadLinks: getDownloadBlock(
        'Haventree_ServicingPrice2025(February25).pdf',
        'Haventree_BaremeDeFrais2025(Fevrier25).pdf'
      ),
    },
    {
      heading: <h2 className="h4">{t('paymentChangeRequestFormServicing')}</h2>,
      downloadLinks: getDownloadBlock(
        'PaymentChangeRequest(03Aug2021).pdf',
        'DemandeModificationPaiement(03Aug2021).pdf'
      ),
    },
    {
      heading: <h2 className="h4">{t('preauthorizedDebitPlanAgreement')}</h2>,
      downloadLinks: getDownloadBlock(
        'Pre-AuthorizedDebit(18April2024).pdf',
        'Accord-Debit-Pre-Autorise.pdf'
      ),
    },
  ];

  const getDownloadLinks = () => {
    return downloadLinks.map((item, i) => {
      return <DownloadBlock key={`${item.heading}-${i}`} {...item} />;
    });
  };

  return <div className={`MortgageServicingDocuments ${className}`}>{getDownloadLinks()}</div>;
};

export default MortgageServicingDocuments;
